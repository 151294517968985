export function getFirebaseConfig () {
  let firebaseConfig = {
    apiKey: "AIzaSyDoR7gtHg6OSxp14D8FSxPh8J909xH5GhU",
    authDomain: "tcc-principal.firebaseapp.com",
    databaseURL: "https://tcc-principal.firebaseio.com",
    projectId: "tcc-principal",
    storageBucket: "tcc-principal.appspot.com",
    messagingSenderId: "264624738721",
    appId: "1:264624738721:web:c94e2c7ef8bb0c5ad03be0",
    measurementId: "G-Y8GZYTDFN2"
  };

  return firebaseConfig;
}
